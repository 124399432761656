import React from "react";
import PropTypes from "prop-types";
import { Disqus } from "gatsby-plugin-disqus";

const Comment = ({ slug, title }) => {
    const disqusConfig = {
        url: `https://dannyfranca.com/${slug.replace("/blog", "blog")}`,
        identifier: slug,
        title: title,
    };
    return (
        <div>
            <Disqus config={disqusConfig} />
        </div>
    );
};

Comment.propTypes = {
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

export default Comment;
