import React from "react";
import { graphql } from "gatsby";
import Layout from "@layout";
import Image from "@ui/image";
import Comment from "@containers/@danny/article/comment";
import EmailSubscription from "@components/email-subscription";

const BlogPostPage = ({ data: { markdownRemark } }) => {
    const { html } = markdownRemark;
    const { slug, readingTime } = markdownRemark.fields;
    const { title, date, categories } = markdownRemark.frontmatter;
    const image = markdownRemark.frontmatter.image;

    return (
        <Layout pageTitle={title}>
            <main className="main-page-wrapper">
                <div className="rn-blog-area rn-section-gap section-separator">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h1 className="h3 pb--30 text-center">
                                    {title}
                                </h1>
                                <div
                                    style={{
                                        display: "flex",
                                        maxHeight: "500px",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Image
                                        style={{
                                            height: "100%",
                                            width: "100%",
                                        }}
                                        src={image}
                                        alt={title}
                                    />
                                </div>
                                <div
                                    className="pt-4 d-flex justify-content-center"
                                    style={{
                                        fontWeight: 700,
                                        color: "var(--color-heading)",
                                    }}
                                >
                                    <span>{categories[0]}</span>
                                    <span className="mx-3">•</span>
                                    <span>{readingTime.text}</span>
                                    <span className="mx-3">•</span>
                                    <span>{date}</span>
                                </div>
                                <div
                                    className="pt--50"
                                    dangerouslySetInnerHTML={{
                                        __html: html,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Comment slug={slug} title={title} />
                        </div>
                    </div>
                </div>
            </main>
        </Layout>
    );
};

export const query = graphql`
    query BlogPost($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            fields {
                slug
                readingTime {
                    text
                }
            }
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                author
                categories
                image {
                    childImageSharp {
                        gatsbyImageData(
                            quality: 100
                            formats: WEBP
                            placeholder: TRACED_SVG
                            outputPixelDensities: [0.25, 0.5, 1, 2]
                        )
                    }
                }
            }
        }
    }
`;

export default BlogPostPage;
